<template>
    <div>
        <ts-page-title :title="$t('dealerGrade.pageTitle')" />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-end">
                    <div class="tw-justify-end tw-space-x-2">
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                    </div>
                </div>
            </ts-panel-wrapper>
            <div id="container-table">
                <ts-table :records="model.dealer_grade_details">
                    <template v-slot:thead>
                        <thead>
                            <tr>
                                <th v-for="(column, key) in columns" :key="key">
                                    {{ column.name }}
                                </th>
                            </tr>
                            <TableProgressBar
                                v-show="loading"
                                :columnLength="columns.length"
                            />
                        </thead>
                    </template>
                    <template v-slot="{ record, index }">
                        <td>
                            <input
                                readonly
                                type="text"
                                :value="record.grade"
                                @input="e => (record.grade = e.target.value)"
                                class="form-control form-control-sm only-border-bottom"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="
                                    errors.has(
                                        'dealer_grade_details.' +
                                            index +
                                            '.grade'
                                    )
                                "
                            >
                                {{
                                    errors.first(
                                        'dealer_grade_details.' +
                                            index +
                                            '.grade'
                                    )
                                }}
                            </div>
                        </td>
                        <td>
                            <input
                                type="text"
                                :value="record.discount_rate"
                                @input="
                                    e => (record.discount_rate = e.target.value)
                                "
                                class="form-control form-control-sm only-border-bottom"
                                :class="{
                                    'is-invalid': errors.has(
                                        'dealer_grade_details.' +
                                            index +
                                            '.holiday_month'
                                    )
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="
                                    errors.has(
                                        'dealer_grade_details.' +
                                            index +
                                            '.discount_rate'
                                    )
                                "
                            >
                                {{
                                    errors.first(
                                        'dealer_grade_details.' +
                                            index +
                                            '.discount_rate'
                                    )
                                }}
                            </div>
                        </td>
                        <td>
                            <select
                                v-model="record.discount_type"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has(
                                        'dealer_grade_details.' +
                                            index +
                                            '.discount_type'
                                    )
                                }"
                            >
                                <option value="%">%</option>
                                <option value="$">$</option>
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="
                                    errors.has(
                                        'dealer_grade_details.' +
                                            index +
                                            '.discount_type'
                                    )
                                "
                            >
                                {{
                                    errors.first(
                                        'dealer_grade_details.' +
                                            index +
                                            '.discount_type'
                                    )
                                }}
                            </div>
                        </td>
                    </template>
                </ts-table>
            </div>
            <ts-panel-wrapper>
                <div class="row tw-mt-6">
                    <div class="col-sm-12 col-lg-12 col-md-12 tw-text-right">
                        <ts-button
                            color="primary"
                            class="ml-2"
                            :waiting="saving"
                            @click.prevent="save"
                        >
                            {{ $t('save') }}</ts-button
                        >
                    </div>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { cloneDeep, debounce } from 'lodash'
import { mapState } from 'vuex'
import TableProgressBar from '@/components/common/TsTable/TableProgressBar'

export default {
    name: 'dealer-grade',
    components: { TableProgressBar },
    data () {
        return {
            model: {
                dealer_grade_details: []
            },
            errors: new Errors(),
            saving: false,
            loading: false,
            width: 'width: 300px'
        }
    },
    computed: {
        ...mapState('customer/dealerGrade', {
            dealerGrades: 'resources',
            pagination: 'pagination'
        }),
        search: {
            get () {
                return this.$store.state.customer.dealerGrade.search
            },
            set (newValue) {
                this.$store.commit('customer/dealerGrade/SET_SEARCH', newValue)
                this.$store.commit('customer/dealerGrade/RESET_CURRENT_PAGE')
            }
        },
        columns () {
            return [
                {
                    name: this.$t('dealerGrade.grade'),
                    sortKey: 'dealer_grade_details.grade'
                },
                {
                    name: this.$t('dealerGrade.discountType'),
                    sortKey: 'dealer_grade_details.discount_type'
                },
                {
                    name: this.$t('dealerGrade.discountRate'),
                    sortKey: 'dealer_grade_details.discount_rate'
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            Promise.all([
                this.$store.dispatch('customer/dealerGrade/fetch', attributes)
            ])
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        save () {
            this.errors = new Errors()
            this.saving = true

            return this.$store
                .dispatch('customer/dealerGrade/store', this.model)
                .then(response => {
                    this.loading = true
                    return this.$store
                        .dispatch('customer/dealerGrade/fetch')
                        .then(() => {
                            this.loading = false
                            this.notice({
                                type: 'success',
                                text: response.message
                            })
                        })
                })
                .catch(error => {
                    if (error.errors) this.errors = new Errors(error.errors)
                    else this.notice({ type: 'error', text: error.message })
                })
                .finally(() => (this.saving = false))
        },
        destroy (record) {
            this.model.dealer_grade_details.splice(
                this.model.dealer_grade_details.indexOf(record),
                1
            )
        },
        addNewRow () {
            this.model.dealer_grade_details.push({
                grade: '',
                discount_rate: '',
                discount_type: ''
            })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'DEALER GRADE',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500),
        dealerGrades: function (dealerGrades) {
            this.model.dealer_grade_details = cloneDeep(dealerGrades)
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('customer/dealerGrade/RESET_STATE')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>

<style lang="css" scoped>
#container-table {
    max-height: 67vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    width: 3px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: rgb(194, 194, 194);
}
.table > thead > tr > th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
}
.table > tbody > tr > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border: none !important;
}
.only-border-bottom {
    border-top: none;
    border-left: none;
    border-right: none;
}
</style>
